
export default {
  components: {
    Checkbox: () => import('primevue/checkbox'),
    InputText: () => import('primevue/inputtext'),
    Button: () => import('primevue/button')
  },
  data() {
    return {
      name: '',
      email: '',
      submitTried: false,
      terms: false,
      done: false,
      error: false,
      title: '',
      text: ''
    }
  },
  methods: {
    validateEmail(email) {
      if (!email || email === '') {
        return false
      }

      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
    },
    async subscribe() {
      this.done = false
      this.error = false
      if (!this.terms || !this.email || !this.name || !this.validateEmail(this.email)) {
        this.submitTried = true
        return
      }
      try {
        this.submitTried = false
        const response = await this.$axios.post(
          '/proxygetresponse/v3/contacts',
          {
            email: this.email,
            name: this.name,
            campaign: {
              campaignId: 2
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Auth-Token': 'api-key d9ceurmnhbx674wtwf9a3p25kzdt7tkl'
            }
          }
        )
        console.log(response)
        if (response && response.status === 202) {
          this.title = this.$t('newsletter.thanksForSubscription')
          this.text = this.$t('newsletter.checkEmail')
        }
      } catch (error) {
        console.log(error, error.response)
        if (error?.response?.status === 409) {
          this.title = this.$t('newsletter.thanks')
          this.text = this.$t('newsletter.alreadySubscribed')
        } else {
          this.error = true
          this.title = this.$t('newsletter.errorTitle')
          this.text = this.$t('newsletter.errorText')
        }
        console.error('Errore:', error)
      } finally {
        this.done = true
        this.name = ''
        this.email = ''
        this.terms = false
      }
    }

  }
}
